<script>
  export default {
    data() {
      return {
        hello: "Hello World",
      };
    },
    mounted() {},
    methods: {
      click() {},
    },
  };
</script>

<template>
  <div
    class="relative total sm:flex-col w-screen h-20 text-yellow-50 flex justify-center items-center text-sm"
  >
    <div class="opacity-70 my-0.5">@UESTC 河畔Lite</div>
    <div class="opacity-70 mx-1 sm:hidden">·</div>
    <div class="opacity-70 my-0.5">Designed by xusun000</div>
  </div>
</template>

<style scoped>
  .total {
    background: #353535;
  }
</style>
