<script>
  export default {
    data() {
      return {
        hello: "Hello World",
      };
    },
    mounted() {},
    methods: {
      click() {},
    },
  };
</script>

<template>
  <div class="mt-40 rounded-b-xl flex flex-col items-center justify-center">
    <div class="flex flex-col justify-center items-center text-center px-10 -mt-24 leading-8">
      <img src="../assets/explore.svg" class="rounded-2xl w-8 h-8 sm:w-16 sm:h-16" alt="" />
      <div class="text-2xl font-semibold mt-9">河畔Lite和一些事</div>
      <div class="max-w-2xl mt-6">
        河畔Lite是电子科技大学校园BBS清水河畔的第三方客户端。此App轻量简洁、设计优雅，拥有诸多特性和功能。
      </div>
      <div class="max-w-2xl mt-4">
        App使用Google开源的跨端页面构建框架Flutter，使用Skia调用GPU直接渲染，渲染速度和表现力十分优秀，并可以移植到macOS、Windows、Linux、Android、IOS等诸多平台。现已在app
        Store上架Release版本「河畔Lite」，感兴趣的同学欢迎体验；也欢迎同学维护代码。
      </div>
      <div class="max-w-2xl mt-4">
        2022年寒假结束后，在家突发奇想想完成早已规划的这个App，从最开始的原型页面到功能调研和模块选择，再到后面的开发，最终在毕业前夕最终完成并上架App
        Store
      </div>
    </div>
  </div>
</template>

<style scoped></style>
