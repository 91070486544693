<template>
  <div class="tootal">
    <WonderFul />
  </div>
</template>

<script>
  import WonderFul from "./components/WonderFul.vue";

  export default {
    name: "App",
    mounted() {
      window.onload = function () {};
    },
    components: {
      WonderFul,
    },
  };
</script>

<style>
  .tootal {
    /* background: #353535; */
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #000;
    margin-top: 60px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
</style>
