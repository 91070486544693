<script>
  export default {
    data() {
      return {
        hello: "Hello World",
        platforms: [
          {
            name: "安卓版",
            icon: require("../assets/logo/1.svg"),
            link: "https://wwb.lanzouj.com/b027a448b",
          },
          {
            name: "Windows版",
            icon: require("../assets/logo/2.svg"),
            link: "https://wwb.lanzouj.com/b027q53wb",
          },
          {
            name: "MacOS版",
            icon: require("../assets/logo/3.svg"),
            link: "https://wwb.lanzouj.com/b027gge2d",
          },
          {
            name: "iOS版",
            icon: require("../assets/logo/4.svg"),
            link: "https://apps.apple.com/cn/app/河畔lite/id1620829749",
          },
        ],
      };
    },
    mounted() {},
    methods: {
      click(url) {
        window.open(url);
      },
    },
  };
</script>

<template>
  <div id="download" class="mb-40 flex items-center justify-center flex-col">
    <div class="text-2xl font-semibold mt-9">下载河畔Lite</div>
    <div class="mt-3">提取密码为1234</div>
    <div class="my-10 flex flex-wrap items-center justify-center">
      <div
        @click="click(item.link)"
        class="cursor-pointer duration-200 my-1 rounded-xl flex flex-col justify-center items-center mx-1 w-40 h-44 sm:w-36 sm:h-44 bg-gray-100"
        v-for="(item, index) in platforms"
        :key="index"
      >
        <div class="h-16">
          <img class="w-10 h-10" :src="item.icon" alt="" />
        </div>
        <div class="font-medium">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
