<script>
  export default {
    data() {
      return {
        hello: "Hello World",
        platforms: [
          {
            name: "Github",
            icon: require("../assets/github.svg"),
            link: "https://github.com/xusun0623/river_lite",
          },
          {
            name: "Gitee",
            icon: require("../assets/gitee.svg"),
            link: "https://gitee.com/xusun000/offershow",
          },
        ],
      };
    },
    mounted() {},
    methods: {
      click(url) {
        window.open(url);
      },
    },
  };
</script>

<template>
  <div id="source" class="mt-20 mb-40 flex items-center justify-center flex-col">
    <div class="duration-200 w-48 sm:w-96 mb-20 h-px bg-slate-200"></div>
    <div class="text-2xl font-semibold mt-9">代码开源地址</div>
    <div class="mt-6 flex flex-wrap items-center justify-center">
      <div
        @click="click(item.link)"
        class="hover:bg-gray-200 duration-200 cursor-pointer rounded-xl m-2 flex items-center w-80 justify-between px-6 py-4"
        v-for="(item, index) in platforms"
        :key="index"
      >
        <div class="flex items-center">
          <img class="w-6 h-6" :src="item.icon" alt="" />
          <div class="ml-4">{{ item.name }}</div>
        </div>
        <img src="../assets/right.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
