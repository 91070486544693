<script>
  export default {
    data() {
      return {
        hello: "Hello World",
      };
    },
    mounted() {},
    methods: {
      toAndriod() {
        window.open("https://wwr.lanzoue.com/b028q8upa");
      },
      toWindows() {
        window.open("https://wwr.lanzoue.com/b028q8usd");
      },
      toMacOS() {
        window.open("https://wwr.lanzoue.com/b028q8uyj");
      },
      click() {
        // get...
      },
    },
  };
</script>

<template>
  <div
    class="relative h-screen flex flex-col justify-between items-center px-10 py-16 sm:px-24 rounded-t-xl"
    style="background-color: #f6f6f6"
  >
    <div class="relative mt-10">
      <div class="">
        <div class="text-4xl font-semibold">河畔Lite</div>
        <div class="text-4xl font-semibold flex flex-wrap sm:flex-row flex-col">
          <div class="mt-3">多端Universal</div>
        </div>
        <div class="mt-5 text-xl">现已支持Windows*、Android*、MacOS*、iOS</div>
      </div>
      <div class="flex items-center mt-12 -ml-2">
        <a
          href="#download"
          class="cursor-pointer select-none px-9 py-3 bg-blue-700 rounded-full text-white font-medium"
        >
          立即下载
        </a>
        <a
          href="#source"
          class="cursor-pointer select-none ml-2 px-9 py-3 bg-blue-100 rounded-full text-blue-700 font-medium"
        >
          开源地址
        </a>
      </div>
<!--      <div class="bg-white rounded-xl mt-14 py-5 px-0">-->
<!--        <div class="ml-4 flex items-center font-bold">-->
<!--          <img src="../assets/openai.svg" class="w-6 h-6 mr-2" alt="" />SimpleGPT 免费&好用&打开就用-->
<!--        </div>-->
<!--        <div class="bg-slate-100" style="height: 1px; margin: 0 20px; margin-top: 22px"></div>-->
<!--        <div-->
<!--          @click="toAndriod"-->
<!--          class="bg-white rounded-xl flex items-center px-4 py-3 justify-between mt-3.5 cursor-pointer hover:bg-slate-100"-->
<!--        >-->
<!--          <div class="flex items-center">-->
<!--            <div class="ml-2">安卓版本下载</div>-->
<!--          </div>-->
<!--          <img src="../assets/right.svg" alt="" />-->
<!--        </div>-->
<!--        <div-->
<!--          @click="toWindows"-->
<!--          class="bg-white rounded-xl flex items-center px-4 py-3 justify-between cursor-pointer hover:bg-slate-100"-->
<!--        >-->
<!--          <div class="flex items-center">-->
<!--            <div class="ml-2">Windows版本下载</div>-->
<!--          </div>-->
<!--          <img src="../assets/right.svg" alt="" />-->
<!--        </div>-->
<!--        <div-->
<!--          @click="toMacOS"-->
<!--          class="bg-white rounded-xl flex items-center px-4 py-3 justify-between cursor-pointer hover:bg-slate-100"-->
<!--        >-->
<!--          <div class="flex items-center">-->
<!--            <div class="ml-2">macOS版本下载</div>-->
<!--          </div>-->
<!--          <img src="../assets/right.svg" alt="" />-->
<!--        </div>-->
<!--        <div class="bg-slate-100" style="height: 1px; margin: 0 20px; margin-top: 10px"></div>-->
<!--        <div class="px-6 mt-6 mb-2 text-sm font-bold">*提取密码均为1234</div>-->
<!--      </div>-->

      <!-- <div class="flex justify-between items-center mb-12 mt-24">
        <div class="text-sm text-gray-400">*Beta版本，可能具有未验证的Bug</div>
        <img src="../assets/logo.svg" class="duration-200 w-8 h-8" alt="" />
      </div> -->
    </div>
  </div>
</template>

<style scoped></style>
