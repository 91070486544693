<script>
  import HomeScreen from "./HomeScreen.vue";
  import FuncCont from "./FuncCont.vue";
  import BottomBar from "./BottomBar.vue";
  import DownloadBtn from "./DownloadBtn.vue";
  import AboutLite from "./AboutLite.vue";
  export default {
    data() {
      return {
        hello: "Hello World",
      };
    },
    mounted() {},
    methods: {
      click() {},
    },
    components: { HomeScreen, FuncCont, BottomBar, DownloadBtn, AboutLite },
  };
</script>

<template>
  <div class="">
    <HomeScreen />
    <FuncCont />
    <AboutLite />
    <DownloadBtn />
    <BottomBar />
  </div>
</template>

<style scoped></style>
